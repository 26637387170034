.experience {
  height: 7vmin;
  width: 7vmin;
  align-items: center;
  justify-content: center;
}

.spanClass{
  background:"black"; 
  border: 2px solid black;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  margin: 1px;
  display:inline-block;
  position: relative;
}

