.CenterIt {
    max-width: 450px;
    height: 100%;
    display: block;
    justify-content: center;
    align-items: center;
  }

  div {
    color: #fff;
  }

p {
    color: white;
  }

table {
    color: white;
    border: 2px solid white;
  }
    
  th {
    border-bottom: 1px solid white;
    color: white;
  }
    
  td {
    text-align: center;
  }