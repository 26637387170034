.CenterItBlock {
    width: 100%;
    padding: 0;
    height: 100%;
    display: inline-grid;
    justify-content: center;
    align-items: center;
    background: rgba(24, 23, 23, 0.1);
  }
.CenterItText {
  
    justify-content: start;
    align-items: flex-start;
		/* width: 50%;  */
    padding-left:10%;
    padding-right:10%;
    padding-top: 2%;
    padding-bottom: 2%;
}
.left{
  text-align: left;
}

p {
    color: white;
  }

table {
    color: white;
    border: 2px solid white;
  }
    
  th {
    border-bottom: 1px solid white;
    color: white;
  }
    
  td {
    text-align: center;
  }