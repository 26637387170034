.bubble{
    background:rgb(35, 34, 34); 
    border: 2px solid white;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 1px;
    display:inline-block;
    position: relative;
  }
  div{
    padding-bottom: 1rem;
  }